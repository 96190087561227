import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <div className="footer">
      Copyright©ITworks. All Rights Reserved.
    </div>
  );
}

export default Footer;
