import React from 'react';
import './support.scss';
import {BsCheckCircle} from "react-icons/bs";

function Support () {
  return (
    <div className="support">
      <div className="title">ITワークス大阪は<span className="pc">、</span><br className="sp"/>このような方を<br className="sp"/>応援しています<span className="pc">。</span></div>
      <div className="check_list">
        <div className="row">
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>就労・定着に向けて特にきめ細かな支援を受けたい方</div>
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>社会人としてのマナーや心横えを身に付けたい方</div>
        </div>
        <div className="row">
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>オフィス系の職場での就労を希望される方</div>
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>一般就労に向けた基本的な生活習慣を身に付けたい方</div>
        </div>
        <div className="row">
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>パソコンスキルを身に付けたい方</div>
          <div className="checkMessage"><div className="check_icon"><BsCheckCircle /></div>長く続けられる仕事に就きたい方</div>
        </div>
      </div>
    </div>
  )
}


export default Support;
