import React from 'react';
import TopMenu from './top_menu';
import NavigationBar from './navigation_bar';
import './header.scss';

function Header({menuOpen, setMenuOpen, pageName, movePage}) {
  return (
    <div className="header">
      <TopMenu movePage={movePage}/>
      <NavigationBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} pageName={pageName} movePage={movePage}/>
    </div>
  );
}

export default Header;
