import React from 'react';
import './flow.scss';

function Flow () {
  return (
    <div className="flow">
      <div className="title">ご利用までの<br className="sp"/>流れ</div>
      <div className="flow_group">
        <div className="flow_info">
          <div className="subtitle">お問合わせ<br />見学・面談</div>
          <br />
          <div className="message">
            事業所の雰囲気や職員・利用者の様子、プログラムの内容等をまずはお気軽に見学ください。見学は常に受付けております。<br /><br />
            お電話または、メールフォームよりお気軽にお問合せ下さい。
          </div>
        </div>
        <div className="flow_info">
          <div className="subtitle">体験利用</div>
          <br />
          <div className="message">
            1〜2日間、ITワークス大阪での作業を実際に体験していただきます。<br/><br />
            雰囲気や環境が気に入り、ご自身の希望する支援が受けられると判断されましたら、正式な手続きに進んで下さい。<br />
          </div>
        </div>
        <div className="flow_info">
          <div className="subtitle">利用申込・受給者証発行</div>
          <br />
          <div className="message">

「障害福祉サービス受給者証」をお持ちでない場合、まずはお住いの市区町村の役所にて「障害福祉サービス受給者証」をご申請いただきます。交付が決定するまで通常２～４週間かかります。<br /><br />
            <div className="description">申請・交付や利用開始日の調整についてはITワークス大阪もお手伝いいたしますのでご安心ください。</div>
          </div>
        </div>
        <div className="flow_info">
          <div className="subtitle">契約</div>
          <br />
          <div className="message">
            ITワークス大阪と利用契約を結んでいただき利用がスタートします。<br /><br />
            利用にあたっては、あなただけの支援計画（個別支援計画）を一緒にたてて、定期的に見直しながら、一般就労に向けた取り組みを進めていきます。
          </div>
        </div>
      </div>
    </div>
  )
}


export default Flow;
