import React from 'react';
import './user.scss';
import avatar_man from '../../img/avatar_man.png';
import avatar_woman from '../../img/avatar_woman.png';
import './business.scss';

function User() {
  return (
    <div className="business" id="voice">
      <div className="title">
        <hr />利用者の声<hr />
      </div>
      <div className="user_voice">
        <div className="profil">
          <div className="user_name sp">利用者Aさん</div>
          <div className="avatar">
            <img src={avatar_man} alt="" />
          </div>
          <br className="sp" />
          性別：男性<br />
          年代：40後半<br />
          障がい種別：精神<br />
          利用頻度：週4日、1日4時間<br />
          利用継続期限：9ヶ月<br />
        </div>
        <div className="vertical_line"></div>
        <div className="user_message">
          <div className="user_name pc">利用者Aさん</div>
          ご時世的にも、これからテレワークが増えていくことと思います。<br />
          I丁ワークス大阪なら、在宅で作業をおこなえるのでテレワークに慣れておくことができます。<br />
          <br />
          在宅なので時間管理を自分でしなければならない点が不安でしたが、<br />
          出勤時にChatworkで挨拶をするルールがあることなどにより生活リズムが<br />
          整い、ほとんど遅れることなく每朝10時に仕事を開始できています。<br />
          また在宅だと、通所のための時間やストレスがまったくあリませんし、<br />
          体力的にも精神的にも余裕が持てるようになります。<br />
          <br />
          現在私は, 主にブログ記事の作成をしていますが, 自分の興味のあるテーマで書けます。<br />
          締め切りなども特になく、自分のペースで仕事を進めていくことができます。<br />
        </div>
      </div>
      <div className="user_voice">
        <div className="profil">
          <div className="user_name sp">利用者Bさん</div>
          <div className="avatar">
            <img src={avatar_woman} alt="" />
          </div>
          <br className="sp" />
          性別：女性<br />
          年代：20代<br />
          障がい種別：精神<br />
          利用頻度：週5日、1日4時間<br />
          利用継続期限：8ヶ月<br />
        </div>
        <div className="vertical_line"></div>
        <div className="user_message">
          <div className="user_name pc">利用者Bさん</div>
          上京して仕事を探していましたが、私にとって人間関係は大きな壁でした。 <br />
          在宅やフリーランスでできる仕事をしようにも、 <br />
          メンタル面や、仕事のマナー面などですぐに働く自信がありませんでした。<br />
          そのため, まずは作業所に行くことを決意。 <br />
        特にパソコン関係に強く, 在宅からでも始められるITワークスさんを選びました。<br />
          <br />
          在宅でやれるという点はまず大きいと思います。 <br />
          自分でもこんなことがやれるんだなと大きな励みになリます。 <br />
          ITワークスの職員の方も気さくで優しい方しかいません。 <br />
          病院でもなかなか自分のことを話せなかったわたしが、 <br />
          こんなことを思ってると話すことができるくらいです。<br />
        </div>
      </div>
      <div className="user_voice">
        <div className="profil">
          <div className="user_name sp">利用者Cさん</div>
          <div className="avatar">
            <img src={avatar_man} alt="" />
          </div>
          <br className="sp" />
          性別：男性<br />
          年代：50代<br />
          障がい種別：身体<br />
          利用頻度：週5日、1日4時間<br />
          利用継続期限：10ヶ月<br />
        </div>
        <div className="vertical_line"></div>
        <div className="user_message">
          <div className="user_name pc">利用者Cさん</div>
          私は、心臓疾患を患い障がい者となったため、今まで通り働き続けることができなくなり、<br />
          長年勤めできた会社を退職しました。<br />
          そんな折に職業紹介の某サイトを閲覧していたところ、<br />
          ITワークスさんの利用者暮集を目にし、「就労支援」というワードが目に止まりました。<br />
          就労の第一歩を踏む出すためのサポートをしてくださることがわかり、<br />
          以前の様な活気がある自分を取り戻すために、勇気を出して入所をさせていただきました。<br />
          将来の就労に向けて、親身になって手助けされていることが、<br />
          スタッフー人ひとりのご対応から見てもわかります。<br />
          それが自分のモチペーションにつながり、社会とのつながりを実感しながら、<br />
          就労に向けて大きく踏み出せています。<br />
          その力、勇気、機会を与えてくださったITワークスさんには、感謝しかありません。<br />
        </div>
      </div>
    </div>
  )
}

export default User;
