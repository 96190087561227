import React from 'react';
import './home.scss';
import './business.scss';
import {FiCheckSquare} from "react-icons/fi";

function Home() {
  return (
    <div className="business" id="home">
      <div className="title">
        <hr />在宅就労支援<hr />
      </div>
      <div className="home_work">
        <div className="subtitle_box">ITワークス大阪では<span className="pc">、</span><br />ご自宅でもお仕事をする事ができます。</div>
        <br /><br />
        <div className="message_box">
          <div className="tele_title">なぜ在宅就労支援が<br className='sp' />できるのか？</div>
          <div className="apricot_box">
            私たちは、IT関係に特化しているため、<br className="pc" />
            在宅就労・在宅学習を行う事ができます。
            <br /><br />
            すべての障がいのある方が平等に就労・学習できるように<br className="pc" />
            サポートさせていただきます。
          </div>
          <div className="support_box">
            <div className="inner_support_box">
              理由により通所ができない方のために支援<br />
              例えば、
              <ul>
                <li>外出する事自体が困難。</li>
                <li>人が多くいる場所に行く事ができない。</li>
                <li>電車やバスなどで通勤をする事ができない。</li>
              </ul>
              <div>
                色々な理由により通所できない方を<br />
                全面的にサポートいたします。
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="arrow_box">
        <div className="arrow yellow">
          <div className="arrow_text">
            <div className="arrow_number">No.1</div>
            <div className="arrow_title">出勤</div><br />
            出退勤はWebで行います。勤怠画面上にて管理を行っております。
          </div>
        </div>
        <div className="arrow green">
          <div className="arrow_text">
            <div className="arrow_number">No.2</div>
            <div className="arrow_title">作業開始</div><br />
            作業中に浮んだ不明点や問題点はリアルタイムでスタッフがすぐに対応いたします。
          </div>
        </div>
        <div className="arrow yellow">
          <div className="arrow_text">
            <div className="arrow_number">No.3</div>
            <div className="arrow_title">就労確認</div><br />
            作業途中でも進捗状況をこまめに確認しサポートいたします。
          </div>
        </div>
        <div className="arrow green">
          <div className="arrow_text">
            <div className="arrow_number">No.4</div>
            <div className="arrow_title">退勤</div><br />
            作業日報の送信とその日の感想を報告いただきます。勤怠画面にて退勤の確認を行います。
          </div>
        </div>
      </div>
      <div className="checklist">
        <div className="check_message">
          <FiCheckSquare/> 出勤した際にその日の作業内容を『Chatwork』でスタッフと確認します。
        </div>
        <div className="check_message">
          <FiCheckSquare/> 作業はすべてパソコンで行うため、在宅でも通所と変わらない事務内容となります。
        </div>
        <div className="check_message">
          <FiCheckSquare/> 在宅就労の場合、より丁寧に連絡をとるように心掛けて対応いたします。
        </div>
        <div className="check_message">
          <FiCheckSquare/> 全てチャットのみで会話するのではなく、不明点や相談事がある場合はすぐにお電話にてお話を伺いサポートいたします。
        </div>
        <div className="check_message">
          <FiCheckSquare/> 退勤時は、報告内容やヒアリング内容を参考に今後の作業内容について一緒に考えたりアドバイスを行っております。
        </div>
      </div>
    </div>
  )
}

export default Home;
