import React from 'react';
import './info.scss';

function Info () {
  return (
    <div className="info">
      <div className="title">事業所概要</div>
      <br />
      <div className="row">
        <div className="sub_title">利用日と利用時間</div>
        <div className="message">
          【利用日】<br />
          月曜日～金曜日<br />
          他、事業所が定める土曜日・祝日を開所<br />
          日曜・年末年始はお休みです<br />
          【利用時間】<br />
          午前９時００分～午後１５時００分　（昼休み１時間）<br />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="sub_title">利用条件</div>
        <div className="message">
          年齢制限は特にございません。障がいや難病をお持ちの方で、お一人で作業をおこなうことができれば、どなたでもご利用いただけます。<br />
          市区町村の許可が下りれば、在宅でのご利用も可能ですので、お気軽にご相談ください！<br />
          <div className="red">ご利用にはお住まいの市区町村から「障害福祉サービス受給者証」を発行していただく必要があります。障害者手帳をお持ちでない方でも利用可能な場合がありますので、まずはお気軽にご相談ください。</div>
          <br />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="sub_title">利用料</div>
        <div className="message">
          本人所得及び配偶者の所得が市区町村民税非課税の場合、無料です。<br />
          課税世帯であっても、上限額が設けられます。<br />
          ITワークス大阪を利用する約９割の方が無料で利用しています。<br />
        </div>
      </div>
    </div>
  )
}


export default Info;
