import React, { useState, useEffect } from 'react';
import './main.scss';
import Header from './header/header';
import Contents from './contents/contents';
import Footer from './footer/footer';
import TopButton from './topButton';

function Main() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pageName, selectPage] = useState("top");
  const [scrollTag, initScrollTag] = useState("");

  useEffect(() => {
    try {
      const scrollStartPosition = document.querySelector(scrollTag);
      if(scrollStartPosition) {
        scrollStartPosition.scrollIntoView();
      }else {
        if("scrollTop" in document.documentElement){
          document.documentElement.scrollTop  = 0;
        }else if("scrollTop" in document.body){
          document.body.scrollTop = 0;
        }
      }
    } catch (e) {
      if("scrollTop" in document.documentElement){
        document.documentElement.scrollTop  = 0;
      }else if("scrollTop" in document.body){
        document.body.scrollTop = 0;
      }
    } finally {

    }
  });

  const movePage = (pageName) => {
      selectPage(pageName);
      initScrollTag("");
  }

  return (
    <div className="wrapper">
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} pageName={pageName} movePage={movePage}/>
      <Contents pageName={pageName} movePage={movePage} initScrollTag={initScrollTag}/>
      <Footer />
      <TopButton />
    </div>
  );
}

export default Main;
