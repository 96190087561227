import React from 'react';
import './intro.scss';

function Intro({introTitle,introMessage,img}) {
  return (
    <div className="intro">
      <div className="left">
       <div className="intro_title">
         {
           // introTitle.map((e,i) => (<div key={"title_line_"+i}>{e}</div>))
         }
         ITワークス大阪で<br className="sp" />おこなう作業とは
       </div>
       <div className="intro_message">
         {
           // introMessage.map((e,i) => (<div key={"message_line_"+i}>{e}</div>))
         }
        作業はご自分のスキルに合わせて自由に選びいただけます。<br />
        <br />
        能力・経験・特性などは人それぞれ異なりますが、<br className="pc"/>
        広い利用者に満足をしていただけるように<br className="pc"/>
        個々に合わせたプランニングをおこなっていきます。<br />
        <br />
        このような作業をおこなってみたい<br />
        このような作業を身に付けたい<br />
        <br />
        といった要望も受け付けておりますのでいつでも<br className="pc"/>お気軽にご相談ください！<br />
       </div>
      </div>
      <div className="right">
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default Intro;
