import React from 'react';
import './qnabox.scss';

function QnaBox({qString, aString}) {
  return (
    <div className="qna_box">
      <div className="question_box"><span>Q.</span>{qString}</div>
      <div className="answer_box"><span>A.</span>{aString.map((e,i) => {
        return (<span key={"answerLine"+i}>{e}<br /></span>);
      })}</div>
    </div>
  );
}

export default QnaBox;
