import React from 'react';
import Intro from './intro';
// import Typing from './typing';
// import Article from './article';
// import Material from './material';
// import Data from './data';
// import Post from './post';
// import Coding from './coding';
import Fee from './fee';
import Target from './target';
import Home from './home';
import User from './user';
import workInfoImg from '../../img/work_info.png';
import './work_info.scss';
import Contact from '../top/contact';

const introTitle = ["ITワークス大阪で","おこなう作業とは"];
const introMessage= [
  "作業はご自分のスキルに合わせて自由に選びいただけます。",
  "",
  "能力・経験・特性などは人それぞれ異なりますが、広い利用者に満足をしていただけるように個々に合わせたプランニングをおこなっていきます。",
  "",
  "このような作業をおこなってみたい",
  "このような作業を身に付けたい",
  "",
  "といった要望も受け付けておりますのでいつでもお気軽にご相談ください！",
];

function WorkInfo({movePage}) {
  return (
    <div>
      <div className="workinfo_title">作業内容</div>
      <Intro introTitle={introTitle} introMessage={introMessage} img={workInfoImg}/>
      <div className="work_info_contents">
        {/* <div className="working_list"><hr />作業一覧<hr /></div>
        <Typing />
        <Article />
        <Material />
        <Data />
        <Post />
        <Coding /> */}
        <Fee />
        <Target />
        <Home />
        <User />
        <Contact movePage={movePage}/>
      </div>
    </div>
  );
}

export default WorkInfo;
