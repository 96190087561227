import React, { useState, useEffect } from 'react';
import {FaArrowAltCircleUp} from 'react-icons/fa'
import './topButton.scss'

function TopButton() {
  const [topBtnStyle, setTopBtnStyle] = useState({
    visibility : "hidden",
  });

  const toTop = () => {
    if("scrollTop" in document.documentElement){
      document.documentElement.scrollTop  = 0;
    }else if("scrollTop" in document.body){
      document.body.scrollTop = 0;
    }
  }

  useEffect(() => {
    window.onscroll = function() {
      if(window.scrollY > 300){
        setTopBtnStyle({
          visibility : "visible",
        });
      }else {
        setTopBtnStyle({
          visibility : "hidden",
        });
      }
    };
  },[]);

  return (
    <div className="topButton" onClick={toTop} style={topBtnStyle}>
      <FaArrowAltCircleUp />
    </div>
  );
}

export default TopButton;
