import React from 'react';
import './navigation.scss';
import {GoThreeBars} from "react-icons/go";

function NavigationBar({menuOpen, setMenuOpen, pageName, movePage}) {
  const clickMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const setMenuClass = (...className) => {
    const basicClassName = className.reduce((x,y) => x+" "+y);
    let tempClassName = basicClassName;
    if(menuOpen) {
      tempClassName += " opened";
    }else {
    }
    if(basicClassName.indexOf(pageName) >= 0){
      tempClassName += " selected";
    }
    return tempClassName;
  }

  const setPage = (pageName) => (() => {
    movePage(pageName);
    setMenuOpen(false);
  });

  return (
    <div className="navigation">
      <div className="three_bar"><GoThreeBars onClick={clickMenu}/></div>
      <div className="items">
        <div className={setMenuClass("item home")} onClick={setPage("top")}>HOME</div>
        <div className={setMenuClass("item about")} onClick={setPage("about")} >ITワークス大阪について</div>
        {undefined && <div className={setMenuClass("item introduce")} onClick={setPage("introduce")} >事業所紹介</div>}
        <div className={setMenuClass("item work_info")} onClick={setPage("work_info")} >作業の内容</div>
        <div className={setMenuClass("item qna")} onClick={setPage("qna")} >よくある質問</div>
        <div className={setMenuClass("item inquiry")} onClick={setPage("inquiry")} >体験予約・お問い合わせ</div>
      </div>
    </div>
  );
}

export default NavigationBar;
