import React from 'react';
import './location.scss';
import Iframe from 'react-iframe';
import logo from '../../img/osaka-logo.png';

const googleMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.0102854358006!2d135.5058075152317!3d34.67968988043997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e722e8e76f6b%3A0xa4e85ae7b20bd28b!2z44CSNTQxLTAwNTcg5aSn6Ziq5bqc5aSn6Ziq5biC5Lit5aSu5Yy65YyX5LmF5a6d5a-655S677yR5LiB55uu77yX4oiS77yZIOWguueti-acrOeUuuODl-ODqeOCtuODk-ODqw!5e0!3m2!1sja!2sjp!4v1599721084588!5m2!1sja!2sjp";

function Location () {
  return (
    <div className="location">
      <div className="title">事業所所在地</div>
      <div className="box">
        <div className="left">
          <div className="subtitle"><img src={logo} alt="logo" /></div>
          <br />
          <div className="location_info">
            <div className="company_name">
              就労継続支援B方事務所<span className="pc">　</span><br className="sp"/>ITワークス
            </div>
            <br />
            <div className="address">
              〒541-0057<br />
              大阪市中央区北久宝寺町1-7-9 堺筋本町プラザビル 201号室
            </div>
            <div>
            TEL:
            <a href="tel:0669488349">06-6948-8349<br/></a>
              FAX:050-3588-4566
            </div>
          </div>
        </div>
        <div className="right">
          <div className="map">
            <Iframe src={googleMap} />
          </div>
        </div>
      </div>
    </div>
  )
}


export default Location;
