import React from 'react';
import './menu.scss';

function Menu({movePage,initScrollTag}) {
  const setPageAndScroll = (pageName, cssSelector="") => (() => {
    movePage(pageName);
    initScrollTag(cssSelector);
  });

  return (
    <div className="menu">
      <div className="row">
        <div className="item info" onClick={setPageAndScroll("work_info",".intro")}>仕事<br className="sp"/>内容</div>
        <div className="item fee" onClick={setPageAndScroll("work_info","#fee")}>工賃</div>
        <div className="item slogan" onClick={setPageAndScroll("about",".info")}>事業所<br className="sp"/>概要</div>
      </div>
      <div className="row">
        <div className="item place" onClick={setPageAndScroll("work_info","#voice")}>利用者<br className="sp"/>の声</div>
        <div className="item home_work" onClick={setPageAndScroll("work_info","#home")}>在宅就労<br className="sp"/>支援</div>
        <div className="item target" onClick={setPageAndScroll("work_info","#target")}>対象と<br className="sp"/>なる方</div>
      </div>
    </div>
  );
}

export default Menu;
