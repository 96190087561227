import React from 'react';
import './top_menu.scss';
import {AiOutlineMail} from "react-icons/ai";
import {BsChatDots} from "react-icons/bs";
import logo from '../img/osaka-logo.png';

function TopMenu({movePage}) {
  const setPage = (pageName) => (() => {
    movePage(pageName);
  });

  return (
    <div className="top_menu">
      <div className="logo" onClick={setPage("top")}><img src={logo} alt="logo"/></div>
      <div className="menu">
        <div className="contact" onClick={setPage("inquiry")}><AiOutlineMail />&nbsp;お問い合わせ・見学のお申し込み</div>
        <div className="qna" onClick={setPage("qna")}><BsChatDots />&nbsp;良くある質問</div>
      </div>
    </div>
  );
}

export default TopMenu;
