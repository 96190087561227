import React from 'react';
import './qna.scss';

function Qna ({movePage, initScrollTag}) {
  const setPage = (pageName) => (() => {
    movePage(pageName);
    initScrollTag(".qna_boxes");
  });
  return (
    <div className="qna">
      <div className="title">良くある質問</div>
        <div className="message">
        <div className="question">Q.パソコン作業しかしないって聞いたけど本当？</div>
        <div className="answer">
          <span>A.</span>ITワークスには関係会社があり、そこからの仕事を受注しているため、すべてパソコンでの作業となります。 スキルがない方、未経験の方でもOK。経験者でも幅広い仕事があります。
        </div>
      </div>
      <br />
      <div className="message">
        <div className="question">Q.利用する方の障がい種類を限定しないって本当？</div>
        <div className="answer">
          <span>A.</span>知的、身体障害を持つ方を受入れない施設は多いですが、どんな方でも迎え入れるオープンな環境を目指しています。
        </div>
      </div>
      <br />
      <div className="message">
        <div className="question">Q.コミュニケーションがとりやすい職場って本当?</div>
        <div className="answer">
          <span>A.</span>事業所で作業をされる場合は、スタッフがその場で直接サポートいたします。事業所は音楽が流れているため、リラックスした環境で作業ができます。在宅で作業をされる方の場合でも、スタッフがスタンバイしておりますので、チャットやお電話でいつでもお気軽にご連絡いただけます。
        </div>
      </div>
      <br /><br />
      <div className="more_question" onClick={setPage("qna")}>一覧を見る</div>
    </div>
  )
}


export default Qna;
