import React from 'react';
import Menu from './menu';
import Support from './top/support';
import Info from './top/info';
import Flow from './top/flow';
import Qna from './top/qna';
import Contact from './top/contact';
import Inquiry from './inquiry/inquiry';
import Location from './top/location';
import About from './about/about';
import QnaList from './qna/qna';
import WorkInfo from './work_info/work_info';
import './contents.scss';
import main_img from "../img/osaka-main.png";


function Contents({pageName, movePage, initScrollTag}) {

  return (
    <div className="contents">
      {pageName === "top" &&
        <div className="top_page">
          <div className="main_img">
            <img src={main_img} alt="main"></img>
          </div>
          <Menu movePage={movePage} initScrollTag={initScrollTag}/>
          <Support />
          <Info />
          <Flow />
          <Qna movePage={movePage} initScrollTag={initScrollTag}/>
          <Location />
          <Contact movePage={movePage}/>
        </div>
      }
      {pageName === "about" &&
        <div className="about_page">
          <About movePage={movePage}/>
        </div>
      }
      {pageName === "inquiry" &&
        <Inquiry />
      }
      {pageName === "qna" &&
        <div className="qna_page">
          <QnaList />
          <Contact movePage={movePage}/>
        </div>
      }
      {pageName === "work_info" &&
        <div className="work_info_page">
          <WorkInfo movePage={movePage}/>
        </div>
      }
    </div>
  );
}

export default Contents;
