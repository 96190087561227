import React from 'react';
import './fee.scss';
import './business.scss';

function Fee() {
  return (
    <div className="business" id="fee">
      <div className="title">
        <hr />工賃<hr />
      </div>
      <div>
        <div className="fee_detail">
          <br />
          <div className="message">
            ①当事業所では、各利用者の作業時間をパソコン上で記録しております。工賃は、<span className="point">作業成果の分</span>だけお支払いしております。
          </div>
          <br/>
          <div className="message">
            ②工賃の計算は単価報酬制となっており、各作業にはそれぞれ<span className="point">単価</span>が定められています。
            <span className="point">作業単価×作業件数＝工賃</span><br />
            <div className="postscript">異なる作業を複数おこなうことも可能です。</div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}

export default Fee;
