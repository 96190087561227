import React from 'react';
import './target.scss';
import './business.scss';

function Target() {
  return (
    <div className="business" id="target">
      <div className="title">
        <hr />対象となる方<hr />
      </div>
      <br />
      <div className="target">
        <div className="subtitle">
          どんな人が働けるのか?
        </div>
        <div className="target_list">
          身体、知的、精神、発達障がいや難病のある方<br />
          スマホ・パソコンに興味がある方<br />
          Web・インターネットに興味がある方<br />
          将来オフィスワークをしたいと思っている方<br />
        </div>
        <div className="targetlist_title">こんな方はお気軽に<br className="sp" />ご相談ください！</div>
        <div className="target_detail">
          <ul>
            <li>障がいをお持ちで一般企業への就労が困難な方</li>
            <li>障がい者手帳をお持ちの方</li>
            <li>障がい福祉サービス受給者症をお持ちの方</li>
            <li>在宅で作業ができる事業所を探している方</li>
            <li>パソコンスキルを身につけながら一般就労を目指したい方</li>
          </ul>
        </div>
        <div className="caution">※上記すべてに該当している必要はございません。</div>
        <br />
        <div className="postscript">
          上記の項目に該当しない方でも、役所の判断で利用可能となる場合がございます。
          <br /><br className="sp" />
          まずは一度ITワークス大阪、もしくはお近くの役所の（障がい）福祉課へご相談ください！
        </div>
      </div>
    </div>
  )
}

export default Target;
