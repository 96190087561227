import React, {useState, useEffect } from 'react';
import counselor from "../../img/counselor.png";
import './tel.scss';

function TeleContact(e) {
  const [page, setPage] = useState('inquiry');
  useEffect(() => {
    setPage(e.pageType);
  },[e]);

  return (
    <div>
      <div className="telephone_contact" id="telephone_contact">
        <div className="telephone_message">
          <div className="telephone_title">お電話でも</div>
          {page === "inquiry" && <div className="telephone_title">気軽にお問い合わせください</div>}
          {page === "reservation" && <div className="telephone_title">気軽に予約ください</div>}
          <div className="telephone_image">
            <img src={counselor} alt="相談員"/>
          </div>
          <div className="telephone_list">
            <div className="telephone_question">1. どんな仕事をするの？</div>
            <div className="telephone_question">2. 私の障害種別でも働けるか心配</div>
            <div className="telephone_question">3. もらえる工賃について詳しく知りたい</div>
          </div>
          <div className="telephone_number">
            <a href="tel:0669488349">06-6948-8349</a>
          </div>
        </div>
      </div>
    </div>
  )
}


export default TeleContact;
