import React, { useState, useEffect, useRef } from 'react';
import './inquiry.scss';
import swal from 'sweetalert';
import Tel from './tel';
import counselor from "../../img/counselor.png";

function Inquiry() {
  const [pageType, setPageType] = useState('inquiry');
  const [formValue, setFormValue] = useState({});
  const [mailClass, setMailClass] = useState("email_check");
  const [inputDisable, setInputDisable] = useState(false);
  const useMountEffect = fun => useEffect(fun, []);
  const myRef = useRef(null);


  const changePage = (type) => () => {
      setPageType(type);
  }

  const isReservationClass = (className) => {
    if(pageType === "reservation"){
      return className+" reservation";
    }else {
      return className;
    }
  }

  const titlePlcaeHold = () => {
    if(pageType === "reservation"){
      return "【見学・相談予約】";
    }else {
      return "【お問い合わせ】";
    }
  }

  const setHiddenValue = (e) => {
    if(pageType === "reservation"){
      return "見学・相談予約";
    }else {
      return "お問い合わせ";
    }
  }

  const inputClear = () => {
    const FormTag = document.querySelector("#inquiry_form");
    if(FormTag.tagName === "FORM"){
      FormTag.reset();
    }
  }

  const submitRequest = () => {
    setInputDisable(true);
    const actionURL = "https://script.google.com/a/itworks.fun/macros/s/AKfycbx0mmcpwP_RN5d0CpR6LYZQsOaO2QgDAYWLeU_9/exec";
    // const actionURL = "https://script.google.com/macros/s/AKfycbwSDD4wV1gStZp6gpffGcI6KwMhVaqWszImHwphJHVirpQTzGLdaqoD2xBP29lvflKI/exec";
    const xhr = new XMLHttpRequest();
    xhr.open('POST', actionURL);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // form.reset();
          swal({
            title: "送信しました。",
            text: "You clicked the button!",
            icon: "success",
            button: "確認",
          }).then((value) => {
            setInputDisable(false);
            inputClear();
          });
        }else {
          swal({
            title: "失敗しました。",
            text: "すみませんがも一度試してくれますか",
            icon: "warning",
            button: "確認",
          }).then((value) => {
            setInputDisable(false);
          });
        }
    };
    var encoded = Object.keys(formValue).map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(formValue[k]);
        }).join('&');

    xhr.send(encoded);
  }

  const clientTime = () => {
    var d = new Date();
    return [d.getMonth()+1,
            d.getDate(),
            d.getFullYear()].join('/')+' '+
           [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    swal({
      title: "上記の内容を送りますか？",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willsend) => {
      if (willsend) {
        delete formValue.email_check;
        formValue.type = setHiddenValue();
        formValue.clientTime = clientTime();
        setFormValue(formValue);
        submitRequest();
      }
    });
    event.stopPropagation();
  }

  const checkEqualMail = (e) => {
    saveInputValue(e);
    if("email" in formValue && "email_check" in formValue && formValue.email === formValue.email_check){
      setMailClass(mailClass.replace(/\s*red/g,""));
    }else {
      if(!/\s*red\s*/.test(mailClass)){
        setMailClass(mailClass+" red");
      }
    }
  }

  const executeScroll = () => myRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  const textAreaStyle = {
    overflow : "hidden"
  }

  const autoHeightTextarea = (e) => {
    var textareaStyle = window.getComputedStyle(e.target);
    if(Math.abs(textareaStyle.height.match(/\d+/)[0] - e.target.scrollHeight) >= 10) {
      e.target.style["height"] = (e.target.scrollHeight - textareaStyle.paddingTop.match(/\d+/)[0] - textareaStyle.paddingBottom.match(/\d+/)[0]) + "px";
      console.log(e.target.scrollHeight);
    }
  }

  const saveInputValue = (e) => {
    formValue[e.target.name] = e.target.value;
    setFormValue(formValue);
    console.log(formValue);
    autoHeightTextarea(e);
  }


  return (
    <div className="inquiry">
      <div className="title">
        {pageType === "inquiry" && <div>お問い合わせ</div>}
        {pageType === "reservation" && <div>見学予約</div>}
      </div>
      <div className="switch">
        <div className="inquiry_btn" onClick={changePage("inquiry")}>お問い合わせ</div>
        <div className="reservation_btn" onClick={changePage("reservation")}>見学予約</div>
      </div>
      <div style={{'textAlign':'center','marginTop':'2rem','color':'green','fontWeight':'bold'}}>
        ITワークス大阪へのお問い合わせ、見学・体験申し込みに関しましては、現在、系列事業所のワンダフル大阪が代表窓口として対応をおこなっております。
        <br/>お問い合わせお待ちしております。
      </div>
      <div className={isReservationClass("form_wrapper")}>
        <form className="form" id="inquiry_form" onSubmit={handleSubmit}>
        <div className="form_top">
          <div className="form_left_side">
             <label><div className="input_title name">お名前</div>
              <input type="text" placeholder="山田 太郎" name="name" onChange={saveInputValue} disabled={inputDisable} required/>
             </label>
             <br /><br />
             <label>メール<br />
             <input type="email" placeholder="example@gmail.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" onChange={saveInputValue} disabled={inputDisable} required/>
             </label>
             <br /><br />
             <label>メール確認<br />
             <input type="email" placeholder="example@gmail.com" name="email_check" onChange={checkEqualMail} className={mailClass} disabled={inputDisable} required/>
             </label>
             <br /><br />
             <div>
               <label>電話番号<br />
                <input type="tel" placeholder="03-1234-5678" pattern="[0-9]{3}-[0-9]{3,4}-[0-9]{4}" name="tel" onChange={saveInputValue} disabled={inputDisable} required/>
               </label>
               <br /><br />
             </div>
             <label>タイトル<br />
             <input type="text" placeholder={titlePlcaeHold()} name="title" onChange={saveInputValue} disabled={inputDisable} required/>
             </label>
           </div>
           <div className="form_right_side">
             <div className="tell_area" onClick={executeScroll}>
              <div className="tell_message">
                <div className="tel_counselor">
                  <img src={counselor} alt="相談員"/>
                </div>
                <div className="tel_button">
                  お電話での<br/>問い合わせはコチラ
                </div>
              </div>
            </div>
           </div>
         </div>
         <br /><br />
         <label>{pageType === "reservation" ? "見学予約内容": "お問い合わせ内容" }<br />
          <textarea form="inquiry_form" name="message" onChange={saveInputValue} style={textAreaStyle} disabled={inputDisable} required></textarea>
         </label>
         <input type="hidden" name="type" value={setHiddenValue()} /><br /><br />
         <input type="submit" value="転送" disabled={inputDisable}/>
        </form>
      </div>
      <div ref={myRef}>
        <Tel pageType={pageType}/>
      </div>
    </div>
  );
}

export default Inquiry;
