import React from 'react';
import QnaBox from './qnabox';
import qnaImgPath from "../../img/qna.png";
import './qna.scss';

const qnaMap = [
  {
    q : ["パソコン作業しかしないって聞いたけど本当？"],
    a : [
      "ITワークスには関係会社があり、そこからの仕事を受注しているため、すべてパソコンでの作業となります。",
      "スキルがない方、未経験の方でもOK。経験者でも幅広い仕事があります。"
    ]
  },
  {
    q : ["利用する方の障がい種類を限定しないって本当？"],
    a : [
      "知的、身体障害を持つ方を受入れない施設は多いですが、どんな方でも迎え入れるオープンな環境を目指しています。"
    ]
  },
  {
    q : ["コミュニケーションがとりやすい職場って本当?"],
    a : [
      "事業所で作業をされる場合は、スタッフがその場で直接サポートいたします。",
      "事業所は音楽が流れているため、リラックスした環境で作業ができます。",
      "在宅で作業をされる方の場合でも、スタッフがスタンバイしておりますので、チャットやお電話でいつでもお気軽にご連絡いただけます。"
      ]
  },
  {
    q : ["初心者だけど大丈夫？"],
    a : [
      "初心者さんは、まずパソコンが使えるようになりましょう。無理せず、ゆっくりと進んで行けば大丈夫です！"
    ]
  },
  {
    q : ["どんな仕事をするの？"],
    a : [
      "決められたルールでデータを打ち込んでいくお仕事や文章を作成する作業などがあります。",
      "基本的にはコピーと貼り付けやタイピングができれば行えます！ ",
      "利用者さんの技術力によって、様々なお仕事を用意できます。 ",
      "お仕事をしながら一緒に考えていきましょう。"
    ]
  }

]

function QnaList() {
  return (
    <div className="qna_list">
      <div className="qna_page_title">良くある質問</div>
      <div className="qna_intro">
        <div className="left">
          <div className="qna_title">良くある質問</div>
          <div className="qna_message">
            このページでは、問い合わせの多かった質問を中心に<br className="pc"/>
            疑問を感じやすい点をまとめました。<br /><br />
            こちらのページに記載がない質問はお問い合わせフォームが<br className="pc"/>
            事業所へ直接お電話にてお問い合わせください。
          </div>
        </div>
        <div className="right">
          <img className="qna_img" src={qnaImgPath} alt="human's question mark"/>
        </div>
      </div>
      <div className="qna_boxes">
        {qnaMap.map((e,i) => {
          return (
            <QnaBox qString={e.q} aString={e.a} key={"QnaBox"+i}/>
          );
        })}
      </div>
    </div>
  );
}

export default QnaList;
