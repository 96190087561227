import React from 'react';
import './contact.scss';
import {AiOutlineMail} from "react-icons/ai";

function Contact ({movePage}) {
  const setPage = (pageName) => (() => {
    movePage(pageName);
  });

  return (
    <div className="contact">
      <div className="title">お問い合わせ</div>
      <div>
        <div className="subtitle">施設についてのお問合せや見学のお申込みなど、お気軽にご連絡下さい。</div>
        <div style={{'textAlign':'center','marginTop':'1rem','color':'green','fontWeight':'bold'}}>
          ITワークス大阪へのお問い合わせ、見学・体験申し込みに関しましては、現在、系列事業所のワンダフル大阪が代表窓口として対応をおこなっております。
          <br/>お問い合わせお待ちしております。
        </div>
        <div className="contact_box">
          <div className="left">
            <div className="contact_info">
              <div>
                TEL:
                <a href="tel:0669488349">06-6948-8349<br/></a>
                FAX:050-3588-4566
              </div>
              <div>
                平日：9:00〜17:00<br />
                土曜日・祝日：9:00～12:00<br />
                ※日曜日・年末年始・その他事業所が定める休日の電話受付はお休みです
              </div>
            </div>
          </div>
          <div className="right">
            <div className="contactbtn" onClick={setPage("inquiry")}>
              <AiOutlineMail /> お問い合わせ
            </div>
            <div className="contact_description">
              365日24時間受付中
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Contact;
